import React from 'react';
import {Button, Typography} from "@material-ui/core";

export interface IItem {
    id: string;
    label: string;
}

export interface IButtonListStep {
    title: string;
    items: IItem[];
    setSelectedItem: Function;
    handleNext: Function;
}

export default function ButtonListStep(props: IButtonListStep) {

    return (
        <div>
            <div className="col-md-6 col-12 mb-3">
                <Typography variant="h5" color={"primary"}>
                    {props.title}
                </Typography>
            </div>
            <div>
                {props.items.map((item, index) => {
                    return <Button key={index}
                                   className="col-5 mt-3 mx-0 ml-3"
                                   variant="contained"
                                   color="primary"
                                   onClick={() => {props.setSelectedItem(item.id); props.handleNext()}}>
                        {item.label}
                    </Button>
                })}
            </div>
        </div>
    );
}