import {createStyles, ListItem, ListItemIcon, ListItemText, Theme} from "@material-ui/core";
import React from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import makeStyles from "@material-ui/core/styles/makeStyles";
import KeycloakService from "../../services/KeycloakService";

export interface ILogoutProperties {
    displayFull?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logout: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        icon: {
            color: 'inherit',
            justifyContent: 'flex-end'
        }
    }));

function handleOnClick(): void {
    KeycloakService.logout();
}

function LogoutMenuItem(props: ILogoutProperties) {

    const classes = useStyles();
    let displayFull = false;

    if (props.displayFull != null) {
        displayFull = props.displayFull;
    }

    if (displayFull) {
        return (
            <ListItem button key={"logout"} onClick={handleOnClick}>
                <ListItemText primary={"Logout"}/>
                <ListItemIcon><ExitToAppIcon/></ListItemIcon>
            </ListItem>
        );
    } else {
        return (
            <div className={classes.logout}>
                <ListItem button key={"logout"} onClick={handleOnClick}>
                    <ListItemIcon className={classes.icon}><ExitToAppIcon /></ListItemIcon>
                </ListItem>
            </div>

        );
    }


}

export default LogoutMenuItem;
