import React, {MouseEventHandler, ReactElement} from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import "./InvoiceCard.css";
import {IconButton} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NumberFormat from 'react-number-format';
import {IInvoiceDocument} from "../../services/InvoiceService";

export interface IInvoiceCardProps {
    invoiceId: number | undefined;
    customerName: string;
    customerLogo: string;
    amount: number;
    documentId: number | '';
    state: string | undefined;
    generateCallback: Function;
    uploadCallback?: MouseEventHandler;
    downloadCallback?: MouseEventHandler;
}

function InvoiceCard(props: IInvoiceCardProps) {

    const [hasMore, setHasMore] = React.useState(!props.documentId);

    function manageDocumentCallback(myFunction: Function) {
        myFunction.call(null,null).then((response: IInvoiceDocument) => {
            setHasMore(!response.id);
        });
    }

    const numberFormat: ReactElement = <NumberFormat displayType="text" thousandSeparator=" " value={(props.amount / 100).toFixed(2)}
                                                     suffix=" €"/>;

    const generateButton: ReactElement =
        <div className="col-5 mt-3 mx-0">
            <Button variant="contained" color="primary" disabled={!hasMore} fullWidth
                    onClick={() => manageDocumentCallback(props.generateCallback)}>Generate</Button>
        </div>;

    const uploadButton: ReactElement = props.uploadCallback ?
        <div className="col-5 mt-3 mx-0">
            <Button variant="outlined" color="secondary" disabled={!hasMore} fullWidth
                    onClick={props.uploadCallback}>Upload</Button>
        </div> :
        <React.Fragment/>;

    const downloadButton: ReactElement = props.downloadCallback ?
        <div className="col-5 mt-3 mx-0">
            <Button variant="outlined" color="secondary" disabled={hasMore} fullWidth
                    onClick={props.downloadCallback}>Download</Button>
        </div> :
        <React.Fragment/>;

    const editButton: ReactElement = props.state === "Draft" ?
        <div className="col-5 mt-3 mx-0">
            <Button variant="contained" color="primary" fullWidth
                    href={"edit-invoice/" + props.invoiceId}>EDIT</Button>
        </div> :
        <React.Fragment/>;


    const logo: ReactElement = props.customerLogo ?
        <CardMedia
            className="col-2 media mt-3"
            image={props.customerLogo}
            title="logo"
        /> :
        <React.Fragment/>;

    return (
        <Card className="root" variant={"outlined"}>
            <div className="row mx-2 justify-content-between">
                <CardHeader
                    className="col-8"
                    title={props.customerName}
                    subheader={numberFormat}/>
                {logo}
                <div className="col-1">
                    <IconButton className="icon-button" edge={"start"}>
                        <MoreVertIcon/>
                    </IconButton>
                </div>
            </div>

            <CardActions className="row justify-content-between mt-3">
                {editButton}
                {generateButton}
                {uploadButton}
                {downloadButton}
            </CardActions>
        </Card>
    );
}

export default InvoiceCard;
