import React from 'react';
import {ICustomer} from "../../../services/CustomerService";
import Wizard from "../../organism/Wizard";
import {IItem} from "../../molecule/ButtonListStep";
import {IProject} from "../../../services/ProjectService";
import {IBMTAlertProps} from "../../molecule/BMTAlert";
import {IInvoice} from "../../../services/InvoiceService";
import {InvoiceFormService} from "../../../services/InvoiceFormService";
import { Formik} from "formik";

import DateRangePickerStep from "../../molecule/DateRangePickerStep";
import ClientSelection from './ClientSelection';
import ProjectsSelection from "./ProjectsSelection";
import InvoiceGenerationForm from "./InvoiceGenerationForm";

function InvoiceGenerationWizard() {

    const initialItems: IItem[] = [];
    const initialState: any[] = [];
    const [customers, setCustomers] = React.useState([] as ICustomer[]);
    const [selectedCustomer, setSelectedCustomer] = React.useState(0);
    const [startPeriod, setStartPeriod] = React.useState(new Date());
    const [endPeriod, setEndPeriod] = React.useState(new Date());
    const [handleNext, setHandleNext] = React.useState( () => function() {});
    const [projects, setProjects] = React.useState(initialItems);
    const [selectedProjects, setSelectedProjects] = React.useState(initialItems);
    const [fullSelectedProjects, setFullSelectedProjects] = React.useState(Array.of<IProject>());
    const [nbWorkHoursByProject] = React.useState(new Map());

    const [paymentMethods, setPaymentMethods] = React.useState(initialState);
    const [alertProps, setAlertProps] = React.useState({open: false} as IBMTAlertProps);
    const [invoice, setInvoice] = React.useState({} as IInvoice);

    const invoiceFormikProps = InvoiceFormService.invoiceFormikProps(invoice, undefined, undefined,
        (hasError) => createAlert('submit', hasError, setAlertProps));

    return (
        <div>

            <Formik
                validateOnMount={invoiceFormikProps.validateOnMount}
                enableReinitialize={invoiceFormikProps.enableReinitialize}
                initialValues={invoiceFormikProps.initialValues}
                validationSchema={invoiceFormikProps.validationSchema}
                onSubmit={invoiceFormikProps.onSubmit}
            >
                {formikProps => (
                    <Wizard setHandleNext={setHandleNext} finalAction={formikProps.submitForm}>

                        <ClientSelection customers={customers} setCustomers={setCustomers}
                                         setSelectedCustomer={setSelectedCustomer} handleNext={handleNext} />

                        <DateRangePickerStep title={"Choose invoice date range"}
                                             defaultStartDate={startPeriod} setStartDate={setStartPeriod}
                                             defaultEndDate={endPeriod} setEndDate={setEndPeriod} />

                        <ProjectsSelection items={projects} selectedItems={selectedProjects} setSelectedItems={setSelectedProjects}
                                           initialItems={initialItems}
                                           selectedCustomer={selectedCustomer}
                                           startPeriod={startPeriod}
                                           endPeriod={endPeriod}
                                           setProjects={setProjects}
                                           setSelectedProjects={setSelectedProjects}
                                           nbWorkHoursByProject={nbWorkHoursByProject}
                        />

                        <InvoiceGenerationForm customers={customers} selectedCustomer={selectedCustomer}
                                               alertProps={alertProps} setAlertProps={setAlertProps} formikProps={formikProps}
                                               fullSelectedProjects={fullSelectedProjects} paymentMethods={paymentMethods}
                                               selectedProjects={selectedProjects} setFullSelectedProjects={setFullSelectedProjects}
                                               nbWorkHoursByProject={nbWorkHoursByProject} setInvoice={setInvoice}
                                               startPeriod={startPeriod} endPeriod={endPeriod} setPaymentMethods={setPaymentMethods}
                        />
                    </Wizard>
                )}
            </Formik>
        </div>
    );
}

export default InvoiceGenerationWizard;

export const createAlert = (type: string, hasError: boolean, setAlert: any): void => {
    if (type === 'submit') {
        if (hasError) {
            setAlert({open: true, message: "Error!", severity: "error"});
        } else {
            setAlert({open: true, message: "Success!", severity: "success"});
        }
        return;
    }
    if (type === 'initError') {
        setAlert({open: true, message: "Init Error!", severity: "error"});
    }
}
