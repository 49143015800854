import HttpClientService from "./HttpClientService";
import {API_BASE_URI} from "../configuration/GlobalConfiguration";

export interface IProject {
    id: number;
    title: string;
    description: string;
    referenceId: number;
    referenceName: string;

}
export interface IProjectDetail {
    id: number;
    title: string;
    description: string;
    referenceId: number;
    referenceName: string;
    dailyRate: number;
    dailyWorkingHours: number;

}

export interface IReference {
    referenceId: number;
    referenceName: string;
}

export const ProjectService = {

    listReferences: function (): Promise<IReference[]> {
        return HttpClientService.get<IReference[]>(API_BASE_URI + '/projects/references');
    },

    listProjectsById: function (ids: number[]): Promise<IProject[]> {
        return HttpClientService.get<IProject[]>(API_BASE_URI + '/projects/?ids=' + ids);
    },

    listProjectsDetailsById: function (ids: number[]): Promise<IProjectDetail[]> {
        return HttpClientService.get<IProjectDetail[]>(API_BASE_URI + '/projects/details/?ids=' + ids);
    }
};
