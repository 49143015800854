import HttpClientService from "./HttpClientService";
import {API_BASE_URI} from "../configuration/GlobalConfiguration";

export interface IDocument {
    id: number;
    fileName: string;
    path: string;
    type: string;
    uploadDate: Date;
    realmId: number;
    realmName: string;
}

export const DocumentService = {

    getDocumentById: function (documentId: number |  '') {
        return HttpClientService
            .get<IDocument>(API_BASE_URI + '/documents/' + documentId);
    }
};