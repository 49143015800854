import Keycloak, {KeycloakError, KeycloakPromise} from "keycloak-js";

const keycloak = Keycloak('/keycloak.json');

const KeycloakService = {

    logout: function(): void {
        keycloak.logout();
    },

    getAccessToken: function(): string | undefined {
        return keycloak.token;
    },

    init: function(): KeycloakPromise<boolean, KeycloakError> {
        return keycloak.init({onLoad: 'login-required', checkLoginIframe: false});
    },
};

export default KeycloakService;
