import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";

const homeStyles = makeStyles(() => ({
  root: {
    margin: 10,
  }
}));

function Home() {
  const classes = homeStyles();

    return (
      <div className={classes.root}>
        <p>Welcome to BMT!</p>
      </div>
    );
}

export default Home;
