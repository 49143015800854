import React from "react";
import CheckBoxListStep, {IItem} from "../../molecule/CheckBoxListStep";
import {ITimeEntry, TimeEntryService} from "../../../services/TimeEntryService";
import {Typography} from "@material-ui/core";

export interface IProjectsSelectionProps {
    items: IItem[];
    selectedItems: IItem[];
    setSelectedItems: Function;
    startPeriod: any;
    endPeriod: any;
    selectedCustomer: any;
    nbWorkHoursByProject: any;
    setProjects: any;
    setSelectedProjects: any;
    initialItems: any;
}

export default function ProjectsSelection(props: IProjectsSelectionProps) {

    const [errorMessage, setErrorMessage] = React.useState('');

    React.useEffect( () => {

        TimeEntryService.listTimeEntriesByPeriod(props.startPeriod, props.endPeriod, props.selectedCustomer)
            .then((response: ITimeEntry[]) => {
                if (response) {
                    if (response.length === 0) {
                        setErrorMessage('No project found for the requested period');
                    } else {
                        setErrorMessage('');
                    }
                    let tmp: IItem[] = [];
                    let item: IItem;
                    let nbWorkHoursByTimeEntry : number;
                    let lastNb: number;

                    props.nbWorkHoursByProject.clear();

                    response.forEach((timeEntry) => {
                        item = {id: timeEntry.projectId.toString(), label: timeEntry.projectTitle};
                        if (tmp.findIndex((currentItem) => currentItem.id === item.id) < 0) {
                            tmp.push(item);
                        }

                        nbWorkHoursByTimeEntry = Math.floor((new Date(timeEntry.endDateTime).getTime()
                            - new Date(timeEntry.startDateTime).getTime()) / 3600000);

                        if (props.nbWorkHoursByProject && props.nbWorkHoursByProject.has(timeEntry.projectId)) {
                            lastNb = (props.nbWorkHoursByProject.get(timeEntry.projectId) ?
                                props.nbWorkHoursByProject.get(timeEntry.projectId) : 0);
                            props.nbWorkHoursByProject.set(timeEntry.projectId,
                                lastNb + nbWorkHoursByTimeEntry);
                        } else {
                            props.nbWorkHoursByProject.set(timeEntry.projectId, nbWorkHoursByTimeEntry);
                        }
                    })
                    props.setProjects(tmp);
                    props.setSelectedProjects(props.initialItems);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedCustomer, props.startPeriod, props.endPeriod]);

    return (
        <div>
            <div>
                <CheckBoxListStep title={"Choose your projects"} items={props.items}
                                  selectedItems={props.selectedItems} setSelectedItems={props.setSelectedItems}/>
            </div>
            <div>
                <Typography paragraph={true} color={"secondary"}>
                    {errorMessage}
                </Typography>
            </div>
        </div>

    )
}

