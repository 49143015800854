import React from 'react';
import {Typography} from "@material-ui/core";
import {Field} from "formik";
import {TextField as TextFieldFormik} from "formik-material-ui";
import BMTSelect from "./BMTSelect";
import BMTDatePicker from "./BMTDatePicker";

export interface IHeaderInvoiceFormProps {
    formProps: any;
    customers: any[];
}

export default function HeaderInvoiceForm(props: IHeaderInvoiceFormProps) {

    return (
        <div>
            <div className="row">
                <div className="col-md-6 col-12 mb-3">
                    <Typography variant="h5" color={"primary"}>INVOICE</Typography>
                </div>
                <div className="col-md-6 col-12 mb-3">
                    <Field component={TextFieldFormik} name="number" variant="outlined"
                           label="Invoice number" required fullWidth/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-12 mb-3">
                    <BMTSelect formProps={props.formProps} fieldName="customerId" label="Customer"
                               items={props.customers} required/>
                </div>
                <div className="col-md-6 col-12 mb-3">
                    <Field component={TextFieldFormik} name="issuer" variant="outlined" label="Issuer"
                           required
                           fullWidth/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-12 mb-3">
                    <BMTDatePicker formProps={props.formProps} fieldName="invoiceDate"
                                   label="Invoice Date"/>
                </div>
                <div className="col-md-6 col-12 mb-3">
                    <BMTDatePicker formProps={props.formProps} fieldName="dueDate" label="Due Date"/>
                </div>
            </div>
        </div>
    );
}