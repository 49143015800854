import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from './component/page/Home';
import {ThemeProvider} from "@material-ui/core";
import theme from "./Theme";
import 'fontsource-roboto';
import Header from "./component/organism/Header";
import CreateInvoice from "./component/page/CreateInvoice";
import EditInvoice from "./component/page/EditInvoice";
import KeycloakService from "./services/KeycloakService";
import Invoices from "./component/page/Invoices";
import InvoiceGenerationWizard from "./component/page/invoice-generation/InvoiceGenerationWizard";

const currentPageTitle = (): string => {
    if (window.location.pathname === '/import-invoice') {
        return 'Import Invoice';
    }
    return 'Billing Management Tool';
}
const App: React.FC = () => {

    const [authenticated, setAuthenticated] = React.useState(false);

    React.useEffect(() => {

        KeycloakService.init().success((authenticated: boolean) => {
            setAuthenticated(authenticated);
        });
    }, []);

    if (!authenticated) {
        return (<div>Loading application...</div>);
    } else {
        return (
            <ThemeProvider theme={theme}>
                <Header title={currentPageTitle()}/>
                <Router>
                    <Switch>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/import-invoice" component={CreateInvoice}/>
                        <Route exact path="/invoices" component={Invoices}/>
                        <Route exact path="/edit-invoice/:id" component={EditInvoice}/>
                        <Route exact path="/invoice-generation-wizard" component={InvoiceGenerationWizard}/>
                    </Switch>
                </Router>
            </ThemeProvider>
        );
    }

};

export default App;
