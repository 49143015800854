import React, {ReactElement} from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';

export interface IWizardProps {
    children: ReactElement[];
    setHandleNext: Function;
    finalAction?: Function;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        step: {
            display: 'flex',
            justifyContent : 'center',
            alignItems: 'center',
        },
        handleButtons: {
            display: 'flex',
            justifyContent : 'center',
            alignItems: 'center',
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
        }
    }),
);

export default function Wizard(props: IWizardProps) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    React.useEffect( () => {
        props.setHandleNext(() => handleNext);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {props.children.map((label, index) => (
                    <Step key={index}>
                        <StepLabel/>
                    </Step>
                ))}
            </Stepper>
            <div className={classes.step}>
                        {props.children[activeStep]}
            </div>
            <div className={classes.handleButtons}>
                <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                >
                    Back
                </Button>
                <Button variant="contained" color="primary"
                        onClick={() =>  {props.finalAction && activeStep === props.children.length - 1 ?
                            props.finalAction() : handleNext()}}>
                    {activeStep === props.children.length - 1 ? 'Finish' : 'Next'}
                </Button>
            </div>
        </div>
    );
}
