import HttpClientService, {ResponsePage} from "./HttpClientService";
import {API_BASE_URI} from "../configuration/GlobalConfiguration";
import {DocumentService} from "./DocumentService";

export interface IInvoice {
    id?: number;
    number: string;
    issuer: string;
    taxFreeAmount?: number;
    vatAmount?: number;
    amount?: number;
    invoiceDate: Date | null;
    dueDate: Date | null;
    customerId: number | '';
    customerName?: string,
    customerLogoURI?: string,
    paymentMethodId: number | '';
    documentId: number | '';
    state?: string;

    billingLines: IBillingLine[];
}

export interface IBillingLine {
    id?: number;
    designation: string | '';
    referenceId: number | '';
    referenceName?: string;
    quantity: number | '';
    taxFreePrice: number | '';
    vatRate: number | '';
}

export interface IInvoiceDocument {
    id: number | '';
    fileName: string | '';
    path: string | '';
    type: string | '';
    uploadDate: Date | null;
    realmId: number | '';
    realmName: string | ''
}

export const InvoiceService = {

    createNewInvoice: function (invoice: IInvoice, document?: File): Promise<IInvoice> {
        return HttpClientService.post<IInvoice>(API_BASE_URI + '/invoices', invoice)
            .then((response) => {
                if (!document || !response || !response.id) {
                    return response;
                }
                const formData = new FormData();
                formData.append('document', document);
                formData.append('invoiceId', response.id.toString());
                return HttpClientService.post<IInvoice, FormData>(API_BASE_URI + '/invoices/documents', formData);
            });
    },

    updateInvoice: function (invoice: IInvoice): Promise<IInvoice> {
        return HttpClientService.put<IInvoice>(API_BASE_URI + '/invoices', invoice);
    },

    listInvoices: function (page: number): Promise<ResponsePage<IInvoice>> {
        return HttpClientService
            .get<ResponsePage<IInvoice>>(API_BASE_URI + '/invoices?size=10&sort=invoiceDate,desc&page=' + page);
    },

    getInvoice: function (invoiceId: number): Promise<IInvoice> {
        return HttpClientService.get<IInvoice>(API_BASE_URI + '/invoices/' + invoiceId);
    },

    downloadInvoiceDocument: function (invoiceId: number | undefined, documentId: number | ''): Promise<void> {

        let fileName: string;

        return DocumentService.getDocumentById(documentId)
            .then(response => {

                fileName = response.fileName;
            }).catch(() => {

                fileName = "invoice_" + new Date().toLocaleDateString();
            }).finally(() => {

                HttpClientService.getArrayBuffer<ArrayBuffer>(API_BASE_URI + '/invoices/download?invoiceId=' + invoiceId)
                    .then(response => {

                        const a = document.createElement("a");
                        a.download = fileName;
                        const blob = new Blob([response], {type: 'application/pdf'});
                        const url = URL.createObjectURL(blob);
                        a.href = url;

                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    });
            });
    },

    generateInvoiceDocument: function (invoiceId: number | undefined): Promise<IInvoiceDocument> {
        let invoiceDocument: IInvoiceDocument = {
            fileName: '',
            id: '',
            path: '',
            realmId: '',
            realmName: '',
            type: '',
            uploadDate: null
        };
        return HttpClientService.post<IInvoiceDocument>(API_BASE_URI + '/invoices/generate?invoiceId=' + invoiceId, invoiceDocument);
    }
};