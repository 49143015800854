import React from 'react';
import {Button, Typography} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";

export interface IDateRangePickerStep {
    title: string;
    defaultStartDate: Date;
    defaultEndDate: Date;
    setStartDate: Function;
    setEndDate: Function;
}

export default function DateRangePickerStep(props: IDateRangePickerStep) {

    function selectMonth(month: string = 'current' || 'last') {
        const date = new Date();
        if (month === 'last') {
            date.setUTCDate(0); // setting date to last day of previous month
        }

        const firstDay =
            new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1));

        // getting last day of the previous month of the next month => last day of current month
        const lastDay =
            new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 0));

        props.setStartDate(firstDay);
        props.setEndDate(lastDay);
    }

    return (
        <div>
            <div className="col-md-6 col-12 mb-3">
                <Typography variant="h5" color={"primary"}>
                    {props.title}
                </Typography>
            </div>
            <Button key="selectLastMonth"
                    className="col-5 mt-3 mx-0 ml-3"
                    variant="contained"
                    color="primary"
                    onClick={() => selectMonth('last')}>
                Select last month
            </Button>
            <Button key="selectCurrentMonth"
                    className="col-5 mt-3 mx-0 ml-3"
                    variant="contained"
                    color="primary"
                    onClick={() => selectMonth('current')}>
                Select current month
            </Button>
            <div className="col-md-6 col-6 flex-column justify-content-between mt-3">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Start date"
                        format="dd/MM/yyyy"
                        value={props.defaultStartDate}
                        onChange={(date: MaterialUiPickersDate) => {props.setStartDate(date ? date: new Date());}}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="end date"
                        format="dd/MM/yyyy"
                        value={props.defaultEndDate}
                        onChange={(date: MaterialUiPickersDate) => {props.setEndDate(date ? date: new Date());}}
                        minDate={props.defaultStartDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </div>
    );
}
