import React from 'react';
import {IReference, ProjectService} from "../../services/ProjectService";
import {CustomerService, ICustomer} from "../../services/CustomerService";
import {IPaymentMethod, PaymentMethodService} from "../../services/PaymentMethodService";
import InvoiceForm from "../organism/InvoiceForm";
import BMTAlert, {IBMTAlertProps} from "../molecule/BMTAlert";
import {Grid} from "@material-ui/core";

function CreateInvoice() {

    const initialState: any[] = [];
    const [customers, setCustomers] = React.useState(initialState);
    const [references, setReferences] = React.useState(initialState);
    const [paymentMethods, setPaymentMethods] = React.useState(initialState);
    const [alertProps, setAlertProps] = React.useState({open: false} as IBMTAlertProps);

    React.useEffect(() => {
        CustomerService.listCustomers()
            .then((response: ICustomer[]) => {
                    if (response) {
                        setCustomers(response)
                    } else {
                        createAlert('initError', true);
                    }
                }
            );
    }, []);

    React.useEffect(() => {
        ProjectService.listReferences()
            .then((response: IReference[]) => {
                    if (response) {
                        setReferences(response.map(reference => {
                            return {
                                id: reference.referenceId,
                                name: reference.referenceName
                            };
                        }))
                    } else {
                        createAlert('initError', true);
                    }
                }
            )
    }, []);

    React.useEffect(() => {
        PaymentMethodService.listPaymentMethods()
            .then((response: IPaymentMethod[]) => {
                    if (response) {
                        setPaymentMethods(response.map(paymentMethod => {
                            return {
                                id: paymentMethod.id,
                                name: paymentMethod.denomination
                            };
                        }))
                    } else {
                        createAlert('initError', true);
                    }
                }
            );
    }, []);

    const createAlert = (type: string, hasError: boolean): void => {
        if (type === 'submit') {
            if (hasError) {
                setAlertProps({open: true, message: "Error!", severity: "error"});
            } else {
                setAlertProps({open: true, message: "Success!", severity: "success"});
            }
            return;
        }
        if (type === 'initError') {
            setAlertProps({open: true, message: "Init Error!", severity: "error"});
        }
    }

    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={1}/>
                <Grid item xs={10}>
                    <Grid container spacing={4} direction="column">
                        <Grid item>
                            <BMTAlert open={alertProps.open} title={alertProps.title} message={alertProps.message}
                                      severity={alertProps.severity}
                                      onClick={() => setAlertProps({...alertProps, open: false})}/>
                        </Grid>
                        <Grid item>
                            <InvoiceForm onSubmit={(hasError) => createAlert('submit', hasError)} customers={customers}
                                         ribs={paymentMethods}
                                         references={references}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default CreateInvoice;
