import React from 'react';
import Alert, {Color} from '@material-ui/lab/Alert';
import {Collapse} from "@material-ui/core";
import {AlertTitle} from "@material-ui/lab";

export interface IBMTAlertProps {
    open: boolean;
    title?: string;
    message?: string;
    severity?: Color;
    onClick?: () => void;
}

export default function BMTAlert(props: IBMTAlertProps) {

    return (
        <Collapse in={props.open}>
            <Alert severity={props.severity} onClick={() => {
                if (props.onClick) {
                    props.onClick();
                }
            }
            }>
                {
                    props.title ? <AlertTitle>{props.title}</AlertTitle> : ''
                }
                {props.message}
            </Alert>
        </Collapse>
    );
}