import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {IInvoice} from "../../services/InvoiceService";
import {groupBy} from "../../utils/ArrayUtils";
import InvoicesGroup from "../organism/InvoicesGroup";
import {toMonthYear} from "../../utils/DateUtils";

export interface IInvoiceListProps {
    hasMore: boolean;
    invoices: IInvoice[];
    loadMore: (page: number) => void;
}

export default function InvoicesList(props: IInvoiceListProps) {

    const loader = <div className="loader" key={0}>Loading ...</div>;

    const invoicesGroupedByMonth = groupBy(props.invoices, (item) =>
        toMonthYear(item.invoiceDate));

    let items: JSX.Element[] = [];
    invoicesGroupedByMonth.forEach((group, name) =>
        items.push(<InvoicesGroup
            key={name + '_' + group.length} name={name}
            invoices={group}/>)
    )

    return (
        <InfiniteScroll
            initialLoad={true}
            pageStart={-1}
            loadMore={props.loadMore}
            hasMore={props.hasMore}
            loader={loader}>
            <div className='row mx-4 mt-5'>
                {items}
            </div>
        </InfiniteScroll>
    );
}
