import React from "react";
import {ICustomer} from "../../../services/CustomerService";
import {Grid} from "@material-ui/core";
import BMTAlert from "../../molecule/BMTAlert";
import {Form} from "formik";
import HeaderInvoiceForm from "../../molecule/HeaderInvoiceForm";
import BillingLineFieldArray from "../../organism/BillingLineFieldArray";
import AmountAndPaymentInvoiceForm from "../../molecule/AmountAndPaymentInvoiceForm";
import {IProject, IProjectDetail, ProjectService} from "../../../services/ProjectService";
import {IBillingLine} from "../../../services/InvoiceService";
import {IItem} from "../../molecule/ButtonListStep";
import {IPaymentMethod, PaymentMethodService} from "../../../services/PaymentMethodService";
import {createAlert} from "./InvoiceGenerationWizard";

export interface IInvoiceGenerationFormProps {
    customers: ICustomer[];
    selectedCustomer: number;
    alertProps: any;
    setAlertProps: any;
    formikProps: any;
    fullSelectedProjects: IProject[];
    paymentMethods: any[];
    selectedProjects: IItem[];
    setFullSelectedProjects: any;
    nbWorkHoursByProject: any;
    setInvoice: any;
    startPeriod: Date;
    endPeriod: Date;
    setPaymentMethods: any;
}

export default function InvoiceGenerationForm(props: IInvoiceGenerationFormProps) {


    React.useEffect(() => {

        ProjectService.listProjectsDetailsById(props.selectedProjects.map( selectedProject => Number.parseInt(selectedProject.id)))
            .then((response: IProjectDetail[]) => {
                if (response) {
                    props.setFullSelectedProjects(response);

                    let currentCustomer: ICustomer| undefined  = props.customers.find((customer: ICustomer) => {
                        return customer.id.toString() === props.selectedCustomer.toString();
                    });

                    let billingLines: IBillingLine[] = [];

                    if (props.selectedProjects.length === 0 ) {
                        billingLines.push({
                            designation: '',
                            quantity: '',
                            referenceId: '',
                            referenceName: '',
                            taxFreePrice: '',
                            vatRate: ''
                        });
                    }

                    response.forEach(((projectDetail: IProjectDetail) => {
                        billingLines.push({
                            designation: projectDetail.description ? projectDetail.description : '',
                            quantity: props.nbWorkHoursByProject.get(projectDetail.id) / projectDetail.dailyWorkingHours,
                            referenceId: projectDetail.referenceId,
                            referenceName: projectDetail.referenceName,
                            taxFreePrice: projectDetail.dailyRate,
                            vatRate: 20
                        });
                    }));
                    const dueDate = props.endPeriod;
                    dueDate.setMonth(dueDate.getMonth() + 1);
                    props.setInvoice({
                        id: undefined,
                        number: '',
                        issuer: '',
                        invoiceDate: new Date(),
                        dueDate: dueDate,
                        customerId: props.selectedCustomer,
                        customerName: currentCustomer ? currentCustomer.name : '',
                        paymentMethodId:  '',
                        documentId:  '',

                        billingLines: billingLines,
                    });
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedProjects]);

    React.useEffect(() => {
        PaymentMethodService.listPaymentMethods()
            .then((response: IPaymentMethod[]) => {
                    if (response) {
                        props.setPaymentMethods(response.map(paymentMethod => {
                            return {
                                id: paymentMethod.id,
                                name: paymentMethod.denomination
                            };
                        }))
                    } else {
                        createAlert('initError', true, props.setAlertProps);
                    }
                }
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container spacing={4} direction="column">
            <Grid item>
                <BMTAlert open={props.alertProps.open} title={props.alertProps.title}
                          message={props.alertProps.message}
                          severity={props.alertProps.severity}
                          onClick={() => props.setAlertProps({...props.alertProps, open: false})}
                />
            </Grid>

            <Grid item>
                <Form>
                    <div className="container">
                        <HeaderInvoiceForm formProps={props.formikProps}
                                           customers={props.customers.filter(customer => customer.id.toString() === props.selectedCustomer.toString())}
                        />

                        <div className="row">
                            <div className="col-12 mb-3">
                                <BillingLineFieldArray formProps={props.formikProps}
                                                       fieldName="billingLines"
                                                       references={props.fullSelectedProjects.map(reference => {
                                                           return {
                                                               id: reference.referenceId,
                                                               name: reference.referenceName
                                                           };
                                                       })}/>
                            </div>
                        </div>

                        <AmountAndPaymentInvoiceForm formProps={props.formikProps} ribs={props.paymentMethods} />
                    </div>
                </Form>
            </Grid>
        </Grid>
    )
}

