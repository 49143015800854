import React from 'react';
import {Checkbox, FormControlLabel, Typography} from "@material-ui/core";

export interface IItem {
    id: string;
    label: string;
}

export interface ICheckBoxListStep {
    title: string;
    items: IItem[];
    selectedItems: IItem[];
    setSelectedItems: Function;
}

export default function CheckBoxListStep(props: ICheckBoxListStep) {

    return (
        <div>
            <div className="col-12 mb-3">
                <Typography variant="h5" color={"primary"}>
                    {props.title}
                </Typography>
            </div>
            <div>
                    {props.items.map((item, index) => {
                        return <FormControlLabel
                            className="align-content-between flex-wrap"
                            key={index}
                            control={<Checkbox
                                checked={props.selectedItems.includes(item)}
                                onChange={ () =>
                                {
                                    if (!props.selectedItems.includes(item)) {
                                        props.selectedItems.push(item);
                                    } else {
                                         props.selectedItems.splice(props.selectedItems.indexOf(item), 1);
                                    }
                                    props.setSelectedItems(Array.from(props.selectedItems))
                                }}
                                name={item.label}
                            />}
                            label={item.label}
                        />
                    })}
            </div>
        </div>
    );
}
