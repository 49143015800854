import React from 'react';
import {ResponsePage} from "../../services/HttpClientService";
import {IInvoice, InvoiceService} from "../../services/InvoiceService";
import InvoicesList from "../organism/InvoicesList";

function Invoices() {

    const initialState: IInvoice [] = [];
    const [hasMore, setHasMore] = React.useState(true);
    const [invoices, setInvoices] = React.useState(initialState)

    const loadMore = (page: number) => {
        setHasMore(false);
        InvoiceService.listInvoices(page)
            .then((response: ResponsePage<IInvoice>) => {
                if (response) {
                    setInvoices(invoices.concat(response.content));
                    setHasMore(!response.last)
                } else {
                    setHasMore(false);
                }
            });
    };

    return (
        <InvoicesList invoices={invoices} hasMore={hasMore} loadMore={loadMore}/>
    );
}

export default Invoices;
