import {ListItem, ListItemText} from "@material-ui/core";
import React from "react";

export interface ILinkMenuItemProps {
    label: string;
    uri: string;
}

function LinkMenuItem(props: ILinkMenuItemProps) {

    return (
        <ListItem button component="a" href={props.uri}>
            <ListItemText primary={props.label} />
        </ListItem>
    );
}

export default LinkMenuItem;
