import HttpClientService from "./HttpClientService";
import {API_BASE_URI} from "../configuration/GlobalConfiguration";

export interface ITimeEntry {
    id: number;
    title: string;
    startDateTime: Date;
    endDateTime: Date;
    billable: boolean;
    invoiceId: number;
    invoiceNumber: string;
    projectId: number;
    projectTitle: string;
    billingLineId: number;
}

export const TimeEntryService = {

    listTimeEntriesByPeriod: function (startPeriod: Date, endPeriod: Date, customerId: number): Promise<ITimeEntry[]> {
        return HttpClientService.get<ITimeEntry[]>(API_BASE_URI + '/cra/time-entries?startDateTime='
            + startPeriod.toISOString() + '&endDateTime=' + endPeriod.toISOString() + '&customerId=' + customerId);
    }

}
