import {createMuiTheme} from "@material-ui/core";
import {orange} from "@material-ui/core/colors";

const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#1DC4CA',
                contrastText: '#ffffff'
            },
            secondary: orange
        }
    })
;

export default theme;
