import {IBillingLine, IInvoice, InvoiceService} from "./InvoiceService";
import {FormikHelpers} from "formik";
import {IDocumentStatus} from "../component/organism/InvoiceForm";
import * as Yup from "yup";

interface IInvoiceFormik {
    validateOnMount: boolean
    enableReinitialize: boolean;
    initialValues: any;
    validationSchema: any;
    onSubmit: (values: IInvoice, formikHelpers: FormikHelpers<IInvoice>) => void;
}

export const InvoiceFormService = {

    invoiceFormikProps: function (invoice: IInvoice | undefined, document: IDocumentStatus | undefined,
                                  setDocument: Function | undefined,
                                  onSubmitAlert: ((hasError: boolean) => void) | undefined) {

        const REQUIRED_FIELD = "Required field";
        const INVALID_VALUE = "Invalid value";

        const formSchema = Yup.object().shape({
            number: Yup.string()
                .required(REQUIRED_FIELD),
            invoiceDate: Yup.date()
                .required(REQUIRED_FIELD)
                .typeError(INVALID_VALUE)
                .nullable(),
            customerId: Yup.mixed()
                .required(REQUIRED_FIELD),
            issuer: Yup.string()
                .required(REQUIRED_FIELD),
            dueDate: Yup.date()
                .required(REQUIRED_FIELD)
                .typeError(INVALID_VALUE)
                .nullable(),
            billingLines: Yup.array()
                .of(
                    Yup.object().shape({
                        designation: Yup.string().required(REQUIRED_FIELD),
                        quantity: Yup.number()
                            .min(0, INVALID_VALUE)
                            .required(REQUIRED_FIELD),
                        taxFreePrice: Yup.number()
                            .min(0, INVALID_VALUE)
                            .typeError(INVALID_VALUE)
                            .required(REQUIRED_FIELD),
                        vatRate: Yup.number()
                            .min(0, INVALID_VALUE)
                            .typeError(INVALID_VALUE)
                    })
                )
        });

        let currentDate = new Date();

        let initialBillingLines: IBillingLine[] = invoice && invoice.billingLines ?
            invoice.billingLines.map((billingLine: IBillingLine) => { return {
                id: billingLine.id,
                designation: billingLine.designation,
                referenceId: billingLine.referenceId,
                quantity: billingLine.quantity,
                taxFreePrice: billingLine.taxFreePrice,
                vatRate: billingLine.vatRate}})
            : [{
                designation: '',
                referenceId: '',
                quantity: '',
                taxFreePrice: '',
                vatRate: ''
            }];

        const initialValues = {
            id: invoice && invoice.id ? invoice.id : undefined,
            number: invoice ? '' + invoice.number : '',
            invoiceDate: invoice ? invoice.invoiceDate : currentDate,
            dueDate: invoice ? invoice.dueDate
                : new Date(currentDate.getFullYear(), currentDate.getMonth() +1, currentDate.getDate()),
            issuer: invoice ? '' + invoice.issuer : '',
            customerId: invoice ? invoice.customerId : '',
            billingLines: initialBillingLines,
            paymentMethodId: invoice ? invoice.paymentMethodId : '',
            documentId: invoice ? invoice.documentId : '',
            state: invoice ? invoice.state : ''
        };

        const onSubmit = (values: IInvoice, formikHelpers: FormikHelpers<IInvoice>) => {

            if (!values.id) {
                InvoiceService.createNewInvoice(values, document && document.value
                    ? document.value.file : undefined)
                    .then((response) => {
                        if (onSubmitAlert) {

                            onSubmitAlert(response === undefined);
                            if (response !== undefined) {
                                formikHelpers.resetForm();

                                if (setDocument) {
                                    setDocument({value: null, error: false});
                                }
                            }
                        }
                    })
                    .finally(() => formikHelpers.setSubmitting(false)
                    )
            } else {
                InvoiceService.updateInvoice(values)
                    .then((response) => {
                        if (onSubmitAlert) {

                            onSubmitAlert(response === undefined);
                        }
                    })
                    .finally(() => formikHelpers.setSubmitting(false)
                    )
            }
        };

        const invoiceFormikProps: IInvoiceFormik = {
            validateOnMount: true,
            enableReinitialize: true,
            initialValues: initialValues,
            validationSchema: formSchema,
            onSubmit: onSubmit,
        }

        return invoiceFormikProps;
    }
};