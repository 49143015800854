import {IInvoice, InvoiceService} from "../../services/InvoiceService";
import React from 'react';
import InvoiceCard from "../molecule/InvoiceCard";
import {Typography} from "@material-ui/core";
import NumberFormat from "react-number-format";
import {API_BASE_URI} from "../../configuration/GlobalConfiguration";

export interface IInvoiceGroupProps {
    name: string;
    invoices: IInvoice[];
}

export default function InvoicesGroup(props: IInvoiceGroupProps) {

    return (
        <React.Fragment>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3 mx-0">
                <div className="row">
                    <Typography className={"col"} variant="h5" display={"inline"} component={"div"}>{props.name}</Typography>
                    <Typography className={"col"} variant="body2" display={"inline"} component={"div"} align={"right"}>
                        <NumberFormat displayType="text" thousandSeparator=" " suffix=" €"
                                      value={props.invoices
                                          .reduce((sum, current) => sum + Number(current.amount) / 100, 0).toFixed(2)}
                        />
                    </Typography>
                </div>
            </div>
            <div className="w-100"/>

            {props.invoices.map((invoice, index) =>
                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-3 mx-0' key={index}>
                    <InvoiceCard invoiceId={invoice.id}
                                 amount={Number(invoice.amount)}
                                 customerLogo={invoice.customerLogoURI ? API_BASE_URI + invoice.customerLogoURI : ''}
                                 customerName={invoice.customerName || ''}
                                 documentId={invoice.documentId}
                                 state={invoice.state}
                                 generateCallback={() => InvoiceService.generateInvoiceDocument(invoice.id)}
                                 downloadCallback={() => InvoiceService.downloadInvoiceDocument(invoice.id, invoice.documentId)}/>
                </div>
            )}
            <div className="w-100"/>
        </React.Fragment>
    );
}