import HttpClientService from "./HttpClientService";
import {API_BASE_URI} from "../configuration/GlobalConfiguration";

export interface ICustomer {
    id: number;
    name: string;
}

export const CustomerService = {

    listCustomers: function (): Promise<ICustomer[]> {
        return HttpClientService.get<ICustomer[]>(API_BASE_URI + '/customers');
    }
};