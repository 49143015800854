import axios, {AxiosRequestConfig} from "axios";
import KeycloakService from "./KeycloakService";

export interface ResponsePage<T> {
    content: T[];
    totalElements:number;
    totalPages:number;
    last:boolean;
    first:boolean;
    number:number;
    size:number;
}

const HttpClientService = {

    get: function <T>(uri: string): Promise<T> {
        return axios.get(uri, {
            headers: {
                'Authorization': `Bearer ${KeycloakService.getAccessToken()}`
            }
        }).then(response => {
            return response.data;
        }, error => {
            if (error.response.status === 401) {
                KeycloakService.logout();
            }
        });
    },

    getArrayBuffer: function <T>(uri: string): Promise<T> {
        return axios.get(uri, {
            responseType: 'arraybuffer',
            headers: {
                'Authorization': `Bearer ${KeycloakService.getAccessToken()}`
            }
        }).then(response => {
            return response.data;
        }, error => {
            if (error.response.status === 401) {
                KeycloakService.logout();
            }
        });
    },

    post: function <T, R = T>(uri: string, body: R): Promise<T> {

        const config: AxiosRequestConfig = {
            headers: {
                'Authorization': `Bearer ${KeycloakService.getAccessToken()}`,
                'Content-Type': `${body instanceof FormData ? 'multipart/form-data' : 'application/json'}`
            }
        };

        return axios.post(uri, body, config).then(response => {
            return response.data;
        }, error => {
            if (error.response.status === 401) {
                KeycloakService.logout();
            }
        });
    },

    put: function <T, R = T>(uri: string, body: R): Promise<T> {

        const config: AxiosRequestConfig = {
            headers: {
                'Authorization': `Bearer ${KeycloakService.getAccessToken()}`,
                'Content-Type': `${body instanceof FormData ? 'multipart/form-data' : 'application/json'}`
            }
        };

        return axios.put(uri, body, config).then(response => {
            return response.data;
        }, error => {
            if (error.response.status === 401) {
                KeycloakService.logout();
            }
        });
    }
};

export default HttpClientService;
