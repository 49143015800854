import {createStyles, Divider, Drawer, IconButton, List, Theme} from "@material-ui/core";
import React, {useEffect, useRef} from "react";
import theme from "../../Theme";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import makeStyles from "@material-ui/core/styles/makeStyles";
import RefreshDataMenuItem from "./RefreshDataMenuItem";
import LinkMenuItem from "./LinkMenuItem";
import LogoutMenuItem from "./LogoutMenuItem";

export interface IMenuProperties {
    open: boolean;
    onClose: () => void;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
    }));

/**
 * Hook that handles clicks outside of the passed ref
 */
function useOutsideClicksHandler(ref: any, onClose: () => void) {
    useEffect(() => {
        /**
         * Closes menu if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClose();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onClose]);
}

function BurgerMenu(props: IMenuProperties) {

    const classes = useStyles();
    const wrapperRef = useRef(null);
    useOutsideClicksHandler(wrapperRef, props.onClose);

    return (
        <Drawer
            ref={wrapperRef}
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={props.open}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader} onClick={props.onClose}>
                <IconButton>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                </IconButton>
            </div>
            <Divider/>
            <List>
                <RefreshDataMenuItem />
                <LinkMenuItem label="Import Invoice" uri="/import-invoice"/>
                <LinkMenuItem label="Generate Invoice" uri="invoice-generation-wizard"/>
                <LinkMenuItem label="Invoices" uri="/invoices"/>
                <LogoutMenuItem displayFull={true} />
            </List>
        </Drawer>
    );
}

export default BurgerMenu;
