import React from 'react';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@material-ui/core";

export interface IBMTSelectProps {
    formProps: any;
    fieldName: string;
    label: string;
    items: any[];
    required?: boolean
}

function BMTSelect(props: IBMTSelectProps) {

    return (
            <FormControl required={props.required} variant="outlined" fullWidth
                         error={props.formProps.errors[props.fieldName] != null
                         && props.formProps.touched[props.fieldName]}>
                <InputLabel id={`${props.fieldName}-label`}>{props.label}</InputLabel>
                <Select
                    id={props.fieldName}
                    labelId={`${props.fieldName}-label`}
                    label={props.label}
                    value={props.formProps.getFieldProps(props.fieldName).value ?
                        props.formProps.getFieldProps(props.fieldName).value : ''}
                    onChange={(event: any) => {
                        props.formProps.setFieldTouched(props.fieldName, true);
                        props.formProps.setFieldValue(props.fieldName, event.target.value,
                            true);
                    }}
                    onBlur={() => props.formProps.setFieldTouched(props.fieldName, true)}

                >
                    {props.items.map(
                        item => {
                            return <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                        }
                    )}
                </Select>
                <FormHelperText>{props.formProps.touched[props.fieldName]
                    ? props.formProps.errors[props.fieldName] : ''
                }</FormHelperText>
            </FormControl>
    );
}

export default BMTSelect;
