import React from 'react';
import {Field} from "formik";
import {TextField as TextFieldFormik} from "formik-material-ui";
import {Card, IconButton, TextField} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import BMTSelect from "../molecule/BMTSelect";
import "./BillingLine.scss";

export interface IBMTBillingLineProps {
    arrayHelpers: any;
    formProps: any;
    index: number;
    fieldName: string;
    references: any[];
}

function BillingLine(props: IBMTBillingLineProps) {
    return (
        <Card variant={"outlined"} className="container billing-line">
            {props.index > 0 ?
                <div className="row justify-content-end">
                    <IconButton aria-label="delete"
                                className="delete"
                                onClick={() => props.arrayHelpers.remove(props.index)}
                    >
                        <CloseIcon/>
                    </IconButton>
                </div> : <div className="row mt-4"/>
            }
            <div className="row mx-1">
                <div className="col-md-8 mb-3 mt-1">
                    <Field component={TextFieldFormik}
                           name={`${props.fieldName}.${props.index}.designation`}
                           variant="outlined"
                           label={"Designation"}
                           fullWidth
                           required
                    />
                </div>
                <div className="col-md-4 mb-3 mt-1">
                    <Field component={TextFieldFormik} name={`${props.fieldName}.${props.index}.quantity`}
                           variant="outlined"
                           type="number"
                           label={"Quantity"}
                           fullWidth
                           required
                    />
                </div>
            </div>
            <div className="row mx-1">
                <div className="col-md-6 mb-3">
                    <Field component={TextFieldFormik}
                           name={`${props.fieldName}.${props.index}.taxFreePrice`}
                           variant="outlined"
                           type="number"
                           label={"Tax Free Unit Price"}
                           fullWidth
                           required
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <TextField variant="outlined"
                               type="number"
                               label="Tax Free Price"
                               fullWidth
                               disabled
                               value={props.formProps.getFieldProps(`${props.fieldName}.${props.index}.quantity`).value
                               * props.formProps.getFieldProps(`${props.fieldName}.${props.index}.taxFreePrice`).value}
                    />
                </div>
            </div>
            <div className="row mx-1">
                <div className="col-md-4 mb-3">
                    <Field component={TextFieldFormik} name={`${props.fieldName}.${props.index}.vatRate`}
                           variant="outlined"
                           type="number"
                           label={"VAT Rate %"}
                           fullWidth
                    />
                </div>
                <div className="col-md-8 mb-3">
                    <BMTSelect formProps={props.formProps}
                               fieldName={`${props.fieldName}.${props.index}.referenceId`}
                               label="Reference" items={[{id: '', name: '-'}].concat(props.references)}/>
                </div>
            </div>
        </Card>
    );
}

export default BillingLine;
