import React from "react";
import {CustomerService, ICustomer} from "../../../services/CustomerService";
import ButtonListStep from "../../molecule/ButtonListStep";

export interface IClientSelectionProps {
    customers: ICustomer[];
    setCustomers: any;
    setSelectedCustomer: any;
    handleNext: Function;
}

export default function ClientSelection(props: IClientSelectionProps) {

    React.useEffect(() => {
        CustomerService.listCustomers()
            .then((response: ICustomer[]) => {
                    if (response) {
                        props.setCustomers(response);
                    }
                }
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ButtonListStep title={"Choose your client"}
                        items={props.customers.map(customer => {
                            return {id: customer.id.toString(), label: customer.name}
                        })}
                        setSelectedItem={props.setSelectedCustomer}
                        handleNext={props.handleNext}
        />
    )
}

