import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import React from "react";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DataService from "../../services/DataService";

function handleOnClick(): void {
    DataService.importClockifyData();
}

function RefreshDataMenuItem() {

    return (
                <ListItem button key={"refresh_data"} onClick={handleOnClick}>
                    <ListItemText primary={"Refresh Data"}/>
                    <ListItemIcon><AutorenewIcon/></ListItemIcon>
                </ListItem>
    );
}

export default RefreshDataMenuItem;
