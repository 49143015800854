import React from 'react';
import {TextField, Typography} from "@material-ui/core";
import BMTSelect from "./BMTSelect";
import {IBillingLine} from "../../services/InvoiceService";

export interface IAmountAndPaymentInvoiceForm {
    formProps: any;
    ribs: any[];
}

export default function AmountAndPaymentInvoiceForm(props: IAmountAndPaymentInvoiceForm) {

    return (
        <div>
            <div className="row">
                <div className="col-12 mb-3">
                    <Typography variant="h6" color={"primary"}>Amount & Payment</Typography>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 col-12 mb-3">
                    <TextField variant="outlined"
                               type="number"
                               label="Tax Free Amount"
                               fullWidth
                               disabled
                               value={props.formProps.values.billingLines
                                   .reduce((sum: number, current: IBillingLine) => sum +
                                       Number(current.taxFreePrice) * Number(current.quantity), 0)}
                    />
                </div>
                <div className="col-md-6 col-12 mb-3">
                    <TextField variant="outlined"
                               type="number"
                               label="VAT Amount"
                               fullWidth
                               disabled
                               value={props.formProps.values.billingLines
                                   .reduce((sum: number, current: IBillingLine) => sum +
                                       Number(current.taxFreePrice) * Number(current.quantity)
                                       * (Number(current.vatRate) / 100), 0)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-12 mb-3">
                    <TextField variant="outlined"
                               type="number"
                               label="Amount"
                               fullWidth
                               disabled
                               value={props.formProps.values.billingLines
                                   .reduce((sum: number, current: IBillingLine) => sum +
                                       Number(current.taxFreePrice) * Number(current.quantity)
                                       * (1 + Number(current.vatRate) / 100), 0)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-3">
                    <BMTSelect formProps={props.formProps} fieldName="paymentMethodId" label="RIB"
                               items={[{id: "", name: '-'}].concat(props.ribs)}/>
                </div>
            </div>
        </div>
    );
}