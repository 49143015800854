import HttpClientService from "./HttpClientService";
import {API_BASE_URI} from "../configuration/GlobalConfiguration";

export interface IPaymentMethod {
    id: number;
    denomination: string;
}

export const PaymentMethodService = {

    listPaymentMethods: function (): Promise<IPaymentMethod[]> {
        return HttpClientService.get<IPaymentMethod[]>(API_BASE_URI + '/payment-methods');
    }
};