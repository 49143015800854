import React from 'react';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";

export interface IBmtDatePickerProps {
    formProps: any;
    fieldName: string;
    label: string;
}

function BMTDatePicker(props: IBmtDatePickerProps) {

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                fullWidth
                label={props.label}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                autoOk
                required
                value={props.formProps.values[props.fieldName]}
                onChange={(date: MaterialUiPickersDate, value: any) => {
                    props.formProps.setFieldTouched(props.fieldName, true);
                    props.formProps.setFieldValue(props.fieldName, date && date.getDate() ? date : value,
                        true);
                }}
                onBlur={() => props.formProps.setFieldTouched(props.fieldName, true)}
                error={
                    props.formProps.errors[props.fieldName] != null
                    && props.formProps.touched[props.fieldName]}
                helperText={props.formProps.touched[props.fieldName]
                    ? props.formProps.errors[props.fieldName] : ''}
                KeyboardButtonProps={{"aria-label": "change date"}}
            />
        </MuiPickersUtilsProvider>
    );
}

export default BMTDatePicker;
