import 'date-fns';
import React from 'react';
import {Form, Formik} from "formik";
import BillingLineFieldArray from "./BillingLineFieldArray";
import {IInvoice} from "../../services/InvoiceService";
import BMTUpload from "../molecule/BMTUpload";
import {File} from 'react-filepond';
import {MAX_UPLOAD_DOCUMENT_SIZE} from "../../configuration/GlobalConfiguration";
import SubmitButtonInvoiceForm from "../molecule/SubmitButtonInvoiceForm";
import HeaderInvoiceForm from "../molecule/HeaderInvoiceForm";
import AmountAndPaymentInvoiceForm from "../molecule/AmountAndPaymentInvoiceForm";
import {InvoiceFormService} from "../../services/InvoiceFormService";
import {Button} from "@material-ui/core";

export interface IInvoiceFormProps {
    customers: any[];
    ribs: any[];
    references: any[];
    onSubmit?: (hasError: boolean) => void;
    invoice?: IInvoice
}

export interface IDocumentStatus {
    value: File | null;
    error: boolean;

}

export default function InvoiceForm(props: IInvoiceFormProps) {

    const [document, setDocument] = React.useState({value: null, error: false} as IDocumentStatus);

    const invoiceFormikProps = InvoiceFormService.invoiceFormikProps(props.invoice, document, setDocument, props.onSubmit);

    return (
        <Formik
            validateOnMount={invoiceFormikProps.validateOnMount}
            enableReinitialize={invoiceFormikProps.enableReinitialize}
            initialValues={invoiceFormikProps.initialValues}
            validationSchema={invoiceFormikProps.validationSchema}
            onSubmit={invoiceFormikProps.onSubmit}
        >
            {formikProps => (

                <Form>
                    <div className="container">
                        <HeaderInvoiceForm formProps={formikProps} customers={props.customers}/>

                        <div className="row">
                            <div className="col-12 mb-3">
                                <BillingLineFieldArray formProps={formikProps} fieldName="billingLines"
                                                       references={props.references}/>
                            </div>
                        </div>

                        <AmountAndPaymentInvoiceForm formProps={formikProps} ribs={props.ribs} />

                        <div className="row">
                            <div className="col-12 mb-3">
                                <BMTUpload
                                    label='<div><img src="/upload.svg" width="43px" height="23px"/><br/>Upload Invoice</div>'
                                    onChange={(file, hasError) => {
                                        setDocument({value: file, error: hasError});
                                    }}
                                    maxFileSize={MAX_UPLOAD_DOCUMENT_SIZE}
                                    acceptedFileTypes={['application/pdf']}
                                    value={document.value}/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-12 mb-3">
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="secondary"
                                    href={"/invoices"}>
                                    Cancel
                                </Button>
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <SubmitButtonInvoiceForm formProps={formikProps} error={document.error}/>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}