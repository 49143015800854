import React from 'react';
import {Button} from "@material-ui/core";

export interface ISubmitButtonInvoiceForm {
    formProps: any;
    error: boolean;
}

export default function SubmitButtonInvoiceForm(props: ISubmitButtonInvoiceForm) {

    return (
        <Button
            variant="contained"
            fullWidth
            color="primary"
            disabled={props.formProps.isSubmitting || !props.formProps.isValid || props.error}
            onClick={props.formProps.submitForm}>
            Submit
        </Button>
    );
}