import React from 'react';
import {FieldArray} from "formik";
import BillingLine from "./BillingLine";
import {IconButton, Typography} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';

export interface IBillingLineFieldArrayProps {
    formProps: any;
    fieldName: string;
    references: any[];
}

function BillingLineFieldArray(props: IBillingLineFieldArrayProps) {

    return (
        <FieldArray
            name={props.fieldName}
            render={arrayHelpers => (
                <div className="container">
                    <div className="row my-3">
                        <Typography variant="h6" display="inline" color={"primary"}>Billing lines</Typography>
                    </div>
                    {props.formProps.values[props.fieldName]
                        .map((item: any, index: number) => (
                            <div className="row mb-3" key={index}>
                                <BillingLine arrayHelpers={arrayHelpers} index={index}
                                             fieldName={props.fieldName} formProps={props.formProps}
                                             references={props.references}/>
                            </div>
                        ))
                    }
                    <div className="row mb-3 justify-content-end">
                        <IconButton aria-label="add"
                                    onClick={() =>
                                        arrayHelpers.push({
                                            designation: '',
                                            referenceId: '',
                                            quantity: '',
                                            taxFreePrice: '',
                                            vatRate: ''
                                        })
                                    }>
                            <AddIcon color={"primary"}/>
                            <Typography variant="h6" display="inline" color={"primary"}>Add billing line</Typography>
                        </IconButton>
                    </div>
                </div>
            )}
        />
    );
}

export default BillingLineFieldArray;
