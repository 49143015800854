import HttpClientService from "./HttpClientService";
import {API_BASE_URI} from "../configuration/GlobalConfiguration";

interface IClockifyImport {

}

const DataService =  {

    importClockifyData: function(): void {
        HttpClientService.get<IClockifyImport>(API_BASE_URI + '/cra/third-party/clockify-import')
            .then(response => {
                console.log(response);
            });
    }
}

export default DataService;
