import React from 'react';
import 'filepond/dist/filepond.min.css';
import {File, FilePond, registerPlugin} from 'react-filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import './BMTUpload.scss';

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

export interface IBMTUploadProps {
    label: string;
    onChange?: (file: File | null, hasError: boolean) => void;
    value?: File | null,
    maxFileSize?: string;
    acceptedFileTypes?: string[];
}

export default function BMTUpload(props: IBMTUploadProps) {

    return (
        <FilePond
            labelIdle={props.label}
            acceptedFileTypes={props.acceptedFileTypes}
            onaddfile={(error, file) => {
                if (props.onChange) {
                    props.onChange(file, error !== null);
                }
            }}
            onremovefile={() => {
                if (props.onChange) {
                    props.onChange(null, false);
                }
            }}
            files={props.value ? [props.value] : undefined}
            maxFileSize={props.maxFileSize}
            allowFileSizeValidation={props.maxFileSize !== undefined}
            fileValidateTypeLabelExpectedTypes={'Expects {allTypes}'}
            fileValidateTypeLabelExpectedTypesMap={{'application/pdf': '.pdf'}}
        >
        </FilePond>
    );
}